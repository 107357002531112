import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    daa: null,
    ddd: null,
    switchOn: false,
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  mutations: {
    c(state, value) {
      state.daa = value
    },
    d(state, value) {
      state.ddd = value
    },
    updateSwitch(state, value) {
      state.switchOn = value;
    }
  },
  strict: process.env.DEV,
})
