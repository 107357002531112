/** @format */

export default [
  {
    path: '/general/dashboard',
    name: 'general-dashboard',
    component: () => import('@/views/GeneralManagement/index.vue'),
    meta: {
      pageTitle: '后台首页',
      breadcrumb: [
        {
          text: '总后台',
        },
      ],
    },
  },

  // 产品管理
  {
    path: '/general/product/brand_management',
    name: 'brand_management',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/ProductManager/Brand'),
    meta: {
      pageTitle: '品牌管理',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '品牌管理',
        },
      ],
    },
  }, // 品牌列表
  {
    path: '/general/product/brand_management/edit',
    name: 'brand_management_edit',
    component: () => import('@/views/GeneralManagement/ProductManager/Brand/BrandEdit'),
    meta: {
      pageTitle: '品牌信息修改',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '品牌管理',
          to: '/general/product/brand_management',
        },
        {
          text: '品牌信息修改',
        },
      ],
    },
  }, // 品牌编辑
  {
    path: '/general/product/brand_management/create',
    name: 'brand_management_create',
    component: () => import('@/views/GeneralManagement/ProductManager/Brand/BrandCreate'),
    meta: {
      pageTitle: '品牌新增',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '品牌管理',
          to: '/general/product/brand_management',
        },
        {
          text: '品牌新增',
        },
      ],
    },
  }, // 品牌创建

  {
    path: '/general/product/category_management',
    name: 'category_management',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/ProductManager/Classification'),
    meta: {
      pageTitle: '分类管理',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '分类管理',
        },
      ],
    },
  }, // 产品分类列表
  {
    path: '/general/product/category_management_create',
    name: 'class_create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/ProductManager/Classification/ClassCreate'),
    meta: {
      pageTitle: '产品分类添加',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '分类管理',
          to: '/general/product/category_management',
        },
        {
          text: '产品分类添加',
        },
      ],
    },
  }, // 产品分类添加
  {
    path: '/general/product/category_management_edit',
    name: 'class_edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/ProductManager/Classification/ClassEdit'),
    meta: {
      pageTitle: '产品分类编辑',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '分类管理',
          to: '/general/product/category_management',
        },
        {
          text: '产品分类编辑',
        },
      ],
    },
  }, // 产品分类编辑

  {
    path: '/general/product/product_list',
    name: 'product_list',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/ProductManager/List'),
    meta: {
      pageTitle: '产品列表',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '产品列表',
        },
      ],
    },
  }, // 产品列表
  {
    path: '/general/product/product_list/edit',
    name: 'product_list_edit',
    component: () => import('@/views/GeneralManagement/ProductManager/List/ListEdit'),
    meta: {
      pageTitle: '产品信息修改',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '产品列表',
          to: '/general/product/product_list',
        },
        {
          text: '产品信息修改',
        },
      ],
    },
  }, // 产品信息修改
  {
    path: '/general/product/product_list/create',
    name: 'product_list_create',
    component: () => import('@/views/GeneralManagement/ProductManager/List/ListCreate'),
    meta: {
      pageTitle: '产品添加',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '产品列表',
          to: '/general/product/product_list',
        },
        {
          text: '产品信息添加',
        },
      ],
    },
  }, // 产品信息添加

  // 会员管理
  {
    path: '/general/member/level_management',
    name: 'level_management',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/VipManager/Level'),
    meta: {
      pageTitle: '等级管理',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '等级管理',
        },
      ],
    },
  },
  {
    path: '/general/member/level_management/create',
    name: 'level_management_create',
    component: () => import('@/views/GeneralManagement/VipManager/Level/LevelCreate'),
    meta: {
      pageTitle: '会员等级新增',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '等级管理',
          to: '/general/member/level_management',
        },
        {
          text: '会员等级新增',
        },
      ],
    },
  },
  {
    path: '/general/member/level_management/edit',
    name: 'level_management_edit',
    component: () => import('@/views/GeneralManagement/VipManager/Level/LevelEdit'),
    meta: {
      pageTitle: '会员等级编辑',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '等级管理',
          to: '/general/member/level_management',
        },
        {
          text: '会员等级编辑',
        },
      ],
    },
  },
  {
    path: '/general/active/activeMember_list',
    name: 'activeMember_management',
    component: () => import('@/views/GeneralManagement/VipManager/active'),
    meta: {
      pageTitle: '活跃会员列表',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '活跃会员列表',
        },
      ],
    },
  },
  {
    path: '/general/member/member_list',
    name: 'member_management',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/VipManager/List'),
    meta: {
      pageTitle: '会员列表',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '会员列表',
        },
      ],
    },
  },
  {
    path: '/general/member/member_list/create',
    name: 'apps-member-create',
    component: () => import('@/views/GeneralManagement/VipManager/List/VipCreate'),
    meta: {
      pageTitle: '会员新增',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '会员管理',
          to: '/general/member/member_list',
        },
        {
          text: '会员新增',
        },
      ],
    },
  },
  {
    path: '/general/member/member_list/edit',
    name: 'apps-member-edit',
    component: () => import('@/views/GeneralManagement/VipManager/List/VipEdit'),
    meta: {
      pageTitle: '会员信息修改',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '会员管理',
          to: '/general/member/member_list',
        },
        {
          text: '会员信息修改',
        },
      ],
    },
  },
  // 保单管理
  {
    path: '/general/policy/policy_list',
    name: 'policy_list',
    component: () => import('@/views/GeneralManagement/OrderManager'),
    meta: {
      pageTitle: '保单列表',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '保单列表',
        },
      ],
    },
  },
  {
    path: '/general/policy/policy_list/create',
    name: 'policy_list_create',
    component: () => import('@/views/GeneralManagement/OrderManager/OrderList/OrderCreate'),
    meta: {
      pageTitle: '保单新增',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '保单新增',
        },
      ],
    },
  },
  {
    path: '/general/policy/policy_list/edit',
    name: 'policy_list_edit',
    component: () => import('@/views/GeneralManagement/OrderManager/OrderList/OrderEdit'),
    meta: {
      pageTitle: '保单编辑',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '保单列表',
          to: '/general/policy/policy_list',
        },
        {
          text: '保单编辑',
        },
      ],
    },
  },
  {
    path: '/general/policy/policy_list/detail',
    name: 'policy_list_detail',
    component: () => import('@/views/GeneralManagement/OrderManager/OrderList/OrderDetail'),
    meta: {
      pageTitle: '保单详情',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '保单列表',
          to: '/general/policy/policy_list',
        },
        {
          text: '保单详情',
        },
      ],
    },
  },
  {
    path: '/general/policy/policy_list/add-money',
    name: 'policy_list_add_money',
    component: () => import('@/views/GeneralManagement/OrderManager/OrderList/AddMoney'),
    meta: {
      pageTitle: '添加付款',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '保单列表',
        },
        {
          text: '添加付款',
        },
      ],
    },
  },
  // 财务管理
  {
    path: '/general/finance/finance_list',
    name: 'tp_data',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/AffairsManager/Tp/index.vue'),
    meta: {
      pageTitle: 'TP数据',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: 'TP数据',
        },
      ],
    },
  },
  {
    path: '/general/finance/commission_list',
    name: 'commission_data',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/AffairsManager/Commission/index.vue'),
    meta: {
      pageTitle: '佣金数据',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '佣金数据',
        },
      ],
    },
  },

  // 前端系统管理
  {
    path: '/general/menu/menu_list',
    name: 'menu_management',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/WebManager/Navigation'),
    meta: {
      pageTitle: '菜单列表',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '菜单管理',
        },
      ],
    },
  },
  {
    path: '/general/menu/article_list',
    name: 'article_management',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/WebManager/Article'),
    meta: {
      pageTitle: '文章管理',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '文章管理',
        },
      ],
    },
  },
  {
    path: '/general/menu/article_list/create',
    name: 'article_create',
    component: () => import('@/views/GeneralManagement/WebManager/Article/ArticleAdd'),
    meta: {
      pageTitle: '文章新增',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '文章管理',
        },
        {
          text: '文章新增',
        },
      ],
    },
  },
  {
    path: '/general/menu/article_list/edit',
    name: 'article_edit',
    component: () => import('@/views/GeneralManagement/WebManager/Article/ArticleEdit'),
    meta: {
      pageTitle: '文章编辑',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '文章管理',
        },
        {
          text: '文章编辑',
        },
      ],
    },
  },

  // 文章分类管理
  {
    path: '/general/menu/article_category_list',
    name: 'article_category',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/WebManager/ArticleClass'),
    meta: {
      pageTitle: '文章分类',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '文章分类',
        },
      ],
    },
  },
  {
    path: '/general/menu/article_category_list/create',
    name: 'article_category-create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/WebManager/ArticleClass/ArticleClassCreate'),
    meta: {
      pageTitle: '文章分类添加',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '文章分类',
        },
      ],
    },
  },
  {
    path: '/general/menu/article_category_list/edit',
    name: 'article_category-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/WebManager/ArticleClass/ArticleClassEdit'),
    meta: {
      pageTitle: '文章分类修改',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '文章分类',
        },
      ],
    },
  },
  {
    path: '/general/video/video_list',
    name: 'resource_management',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/WebManager/Video'),
    meta: {
      pageTitle: '视频列表',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '视频列表',
        },
      ],
    },
  },
  {
    path: '/general/menu/video_list/create',
    name: 'video_create',
    component: () => import('@/views/GeneralManagement/WebManager/Video/VideoAdd'),
    meta: {
      pageTitle: '视频新增',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '视频管理',
        },
        {
          text: '视频新增',
        },
      ],
    },
  },
  {
    path: '/general/menu/video_list/edit',
    name: 'video_edit',
    component: () => import('@/views/GeneralManagement/WebManager/Video/VideoEdit'),
    meta: {
      pageTitle: '视频编辑',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '视频管理',
        },
        {
          text: '视频编辑',
        },
      ],
    },
  },
  {
    path: '/calendar/index',
    name: 'calendar_lit',
    component: () => import('@/views/calendar/Calendar.vue'),
    meta: {
      pageTitle: '日历',
      breadcrumb: [
        {
          text: '日历',
        },
      ],
    },
  },
  // 信箱管理
  {
    path: '/general/mailbox/mailbox_list',
    name: 'mailbox_list',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/vip_web/LetterManager/index'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      // pageTitle: '信箱列表',
      // breadcrumb: [
      //   {
      //     text: '总后台',
      //     to: '/',
      //   },
      //   {
      //     text: '信箱列表',
      //   },
      // ],
    },
  },

  // {
  //   path: '/vip/messageList',
  //   name: 'vip_message-list',
  //   // component: () => import('@/views/email/Chat'),
  //   component: () => import('@/views/vip_web/LetterManager/index'),
  //   meta: {
  //     // pageTitle: '信件管理',
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'chat-application',
  //     // breadcrumb: [
  //     //   {
  //     //     text: '信件查看',
  //     //   },
  //     // ],
  //   },
  // },

  {
    path: '/general/downline_agent',
    name: 'downline_agent',
    component: () => import('@/views/vip_web/Info/next_person'),
    meta: {
      pageTitle: 'Downline Agent',
      breadcrumb: [
        {
          text: 'Downline Agent',
        },
      ],
    },
  },
  {
    path: '/general/active/activeMember_list',
    name: 'notActiveMember_management',
    component: () => import('@/views/GeneralManagement/VipManager/NotActive'),
    meta: {
      pageTitle: '不活跃会员',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '不活跃会员',
        },
      ],
    },
  },
  {
    name: 'team_reward',
    path: '/general/team_reward',
    component: () => import('@/views/GeneralManagement/OrderManager/OrderList/TeamReward'),
    meta: {
      pageTitle: '团队奖励',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '团队奖励',
        },
      ],
    },
  },
  {
    name: 'Earnings_record',
    path: '/Earnings/record',
    component: () => import('@/views/EarningsRecord/OrderList'),
    meta: {
      pageTitle: '收益记录',
      breadcrumb: [
        {
          text: '收益记录',
        },
      ],
    },
  },
  {
    name: 'Commission_record',
    path: '/Commission/record',
    component: () => import('@/views/GeneralManagement/Commission_record/OrderList'),
    meta: {
      pageTitle: '佣金记录',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '佣金记录',
        },
      ],
    },
  },
  {
    name: 'Commission_record_detail',
    path: '/Commission/record/detail',
    component: () => import('@/views/GeneralManagement/Commission_record/OrderDetail'),
    meta: {
      pageTitle: '佣金记录',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '佣金记录',
        },
      ],
    },
  },
  {
    name: 'pay_record',
    path: '/pay/record',
    component: () => import('@/views/GeneralManagement/pay/OrderList'),
    meta: {
      pageTitle: '支付记录',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '支付记录',
        },
      ],
    },
  },
  // 轮播图管理
  {
    path: '/general/menu/banner_list',
    name: 'banner_list',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/WebManager/Banner'),
    meta: {
      pageTitle: '轮播图管理',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '轮播图管理',
        },
      ],
    },
  },
  {
    path: '/general/menu/banner/create',
    name: 'banner-create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/WebManager/Banner/Add'),
    meta: {
      pageTitle: '轮播图添加',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '轮播图添加',
        },
      ],
    },
  },
  {
    path: '/general/menu/banner/edit',
    name: 'banner-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/WebManager/Banner/Edit'),
    meta: {
      pageTitle: '轮播图修改',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: '轮播图修改',
        },
      ],
    },
  },

  {
    path: '/general/menu/birthday_list',
    name: 'birthday_list',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/WebManager/Birthday'),
    meta: {
      pageTitle: 'ATT 经纪人',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: 'ATT 经纪人',
        },
      ],
    },
  },
  {
    path: '/general/menu/birthday/create',
    name: 'birthday-create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/WebManager/Birthday/Add'),
    meta: {
      pageTitle: 'ATT经纪人图添加',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: 'ATT经纪人图添加',
        },
      ],
    },
  },
  {
    path: '/general/menu/birthday/edit',
    name: 'birthday-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/GeneralManagement/WebManager/Birthday/Edit'),
    meta: {
      pageTitle: 'ATT经纪人图修改',
      breadcrumb: [
        {
          text: '总后台',
          to: '/',
        },
        {
          text: 'ATT经纪人图修改',
        },
      ],
    },
  },

 // Scoreboards
 {
  path: '/vip/inforce-TP',
  name: 'vip_inforce_TP',
  component: () => import('@/views/GeneralManagement/VipManager/scoreboards/inforce_TP'),
  meta: {
    pageTitle: 'Inforce TP',
    breadcrumb: [
      {
        text: 'Inforce TP',
      },
    ],
  },
},
{
  path: '/vip/pending-TP',
  name: 'vip_pending_TP',
  component: () => import('@/views/GeneralManagement/VipManager/scoreboards/pending_TP'),
  meta: {
    pageTitle: 'Pending TP',
    breadcrumb: [
      {
        text: 'Pending TP',
      },
    ],
  },
},
{
  path: '/vip/recruiting',
  name: 'vip_recruiting',
  component: () => import('@/views/GeneralManagement/VipManager/scoreboards/recruiting'),
  meta: {
    pageTitle: 'Recruiting',
    breadcrumb: [
      {
        text: 'Recruiting',
      },
    ],
  },
},
]