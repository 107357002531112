/** @format */

import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

import vipForuntWeb from './routes/vip-frount/vip-frount'
import VipRoutes from './routes/vip_in/vip_routes'
import GeneralManagement from './routes/General/General-routes'

Vue.use(VueRouter)

const result = () => {
  const role = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).role : null
  console.log(localStorage.getItem('userData'))
  if (role == '会员') {
    return [...apps, ...pages, ...vipForuntWeb, { path: '/', redirect: '/vip/home' }]
  }
  if (role == '管理员') {
    return [...apps, ...pages, ...GeneralManagement, { path: '/', redirect: '/general/dashboard' }]
  }
  return [...pages, ...apps, ...vipForuntWeb, ...GeneralManagement, { path: '/', redirect: '/login' }]
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    // { path: '/', redirect: { name: 'vip_home' } }, // vip/home
    // {
    //   path: '/pages/miscellaneous/not-authorized',
    //   name: 'misc-not-authorized',
    //   component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    //   meta: {},
    // },
    // ...apps,
    // ...pages,
    // ...vipForuntWeb,
    // ...GeneralManagement,
    ...result(),
    // ...dashboard,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...VipRoutes,
    // ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ path: 'auth-login' })
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
