/** @format */

import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
// import { createToastInterface } from 'vue-toastification'

// const options = {
//   position: 'top-right',
//   autoClose: 5000,
//   hideProgressBar: false,
//   closeOnClick: true,
//   pauseOnHover: true,
//   draggable: true,
// }
//
// const toast = createToastInterface(options)

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 500000,
  headers: {
    token: localStorage.getItem('accessToken'),
  },
})

axiosIns.interceptors.request.use(config => {
  config.headers.token = localStorage.getItem('accessToken')
  return config
})

axiosIns.interceptors.response.use(
  response => {
    const { data } = response
    if (response.status != 200) {
      return Promise.reject(data)
    }
    if (data.code == -1 && data.msg.indexOf('登录') > -1) {
      localStorage.clear()
      // toast.error('登录已过期，请重新登录')
      setTimeout(() => {
        router.push('/login').then(() => {})
        return Promise.reject(data)
      }, 2000)
    }

    return response.data
  },
  errors => Promise.reject(errors),
)

Vue.prototype.$http = axiosIns

export default axiosIns
