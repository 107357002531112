/** @format */

import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Carousel3d, { Carousel3d as Carousel3dCom, Slide } from 'vue-carousel-3d'

import { BForm, BFormGroup, BFormInput, BInputGroup, BButton, BCol, BFormRadioGroup, BRow, BCard, BPagination, BDropdownItem, BDropdown } from 'bootstrap-vue'
import { Image } from 'element-ui'

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.component('BForm', BForm)
Vue.component('BInputGroup', BInputGroup)
Vue.component('BButton', BButton)
Vue.component('BCol', BCol)
Vue.component('BFormRadioGroup', BFormRadioGroup)
Vue.component('BRow', BRow)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BFormInput', BFormInput)
Vue.component('BCard', BCard)
Vue.component('BPagination', BPagination)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BDropdown', BDropdown)
Vue.component('Carousel3d', Carousel3dCom)
Vue.component('Slide', Slide)
Vue.use(Carousel3d)
Vue.component('ElImage', Image)
