export default {
  // Endpoints
  loginEndpoint: '/login/login',
  registerEndpoint: '/login/register',
  emailEndpoint: '/login/sendEmailCode',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',
  classEndpoint: '/pro-cate/list',
  chanpinEndpoint: '/product/list',
  chanpinaddEndpoint: '/product/create',
  orderEndpoint: '/order/list',
  chanpineditEndpoint: '/pro-cate/edit',
  loginInfo: '/user/userinfo',
  upInfo: '/user/editUserInfo',
  tree: '/user/childrenUser3',
  indexhead: '/index/top',
  baifen: '/index/percent',
  pdf_email: 'check/checkEmailCode',
  pdf_email_point: 'check/sendEmailCode',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
